console.group("inicializando o pack css do site")

import './application.scss'
import './overrides'
import './recyclable'
import './components/address'
import './components/directors'
import './components/feedbacks'
import './components/staff'
import './components/institucional'
import './components/apply'
import './components/main'
import './components/team_groups'
import './components/overall'
import './components/projects'
import './components/proposal'
import './components/story'
import './components/wannabe'
import './components/notifications'
import './components/rooms'
import './components/events'
import './components/registrations'
import './components/gallery_images'

console.groupEnd();
